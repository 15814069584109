<template>
    <div class="grid crud-demo">
      <Loader v-model="loading" />
      <div class="col-12">
        <div class="card">
          <Panel header="Administrar Cotizacion">
            <Toast />
            <Dialog v-model:visible="documentDialog" :closeable="true" :style="{ width: '75vw'}" :model="true">
              <QuotationUI :id="id_document" :isModal="true" />
            </Dialog>
          <BasicFormToolbar @list="list" :actions="['list']" />
          <div class="grid">
            <div class="col-12">
                  <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />
                  <Fieldset legend="Informacion General">
                    <div class="p-fluid formgrid grid">
                      <FormCalendar wrapperClass="field col-2" label="Inicio" v-model="entity.initial_date"/>
                      <FormCalendar wrapperClass="field col-2" label="Fin" v-model="entity.final_date"/>   
                      <FormInputText v-model="entity.receiver_name" :label="'Cliente'" :wrapperClass="'field col-4'" :search="true" @search="customerHelper.visible = true" />
                    </div> 
                  </Fieldset> <br>
                  <Fieldset :toggleable="true" legend="Resultados">
                    <BasicDatatable 
                      :exportFilename="'ADMINISTRADOR_COTIZACION'"
                      :selectionMode="'multiple'" 
                      :headers="headers" 
                      :rows="entities" 
                      :rowaction="true"
                      :rowdelete="true"
                      @deleted="cancelar"
                      :rowviewDocument="true"
                      @viewDocument="viewDocument"
                    />
                  </Fieldset>
            </div>
          </div>
          </Panel>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    HeaderGrid,
    ErrorToast,
    Toast,
    fillObject,
  } from "../../../utilities/General";
  import Loader from "../../../components/general/Loader.vue";
  import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
  import FormCalendar from "../../../components/general/FormCalendar.vue";
  import BasicDatatable from "../../../components/general/BasicDatatable.vue";
  import Session from "../../../mixins/sessionMixin";
  import { Quotation } from '../../../models/crm/Quotation';
  import { Customer } from '../../../models/comercial/Customer';
  import Helper from '../../../components/general/HelperDialog.vue';
  import FormInputText from "../../../components/general/FormInputText.vue";
  import moment from 'moment';
  import QuotationUI from "../../../pages/CRM/Aplicaciones/Quotation.vue";
  
  export default {
    mixins: [Session],
    data() {
      return {
        entity: new Quotation(this.session),
        customers: [],
        quotations: [],
        parent: null,
        filters: {},
        entities: [],
        id_document: null,
        documentDialog: null,
        headers: [
          new HeaderGrid("Estatus", "status_name",{type: 'component', component: 'Tag'}),
          new HeaderGrid("Folio", "id"),
          new HeaderGrid("Fecha", "date", { type: 'date' }),
          new HeaderGrid("Cliente", "customer_name"),
          new HeaderGrid("Tipo Venta", "commercial_business"),
          new HeaderGrid("Total", "total"),
        ],
        customersHeaders: [
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Apellidos', 'last_name'),
                new HeaderGrid('RFC', 'rfc')
            ],
        loading: false,
        customerHelper: {
                visible: false,
            },
      };
    },
    components: { FormCalendar, FormInputText, BasicDatatable,Loader,BasicFormToolbar, Helper, QuotationUI },
    created() {
      this.entity = new Quotation(this.session);
    },
    async mounted() {
      await this.refresh();
    },
    methods: {
        selectCustomer(customer) {
            this.entity.id_customer = customer.id; 
            this.entity.receiver_name = customer.name;
        },
      async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new Quotation().data({
            branch: this.session.branch,
            company: this.session.company,
            id_customer: this.entity.id_customer,
            initial_date: moment(this.entity.initial_date).format(),
            final_date: moment(this.entity.final_date).format(),
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
      async cancelar(entity) {
            // this.loading = true;
            this.entity = fillObject(this.entity, entity);
            try {
                if (this.entity.status == 9)
                throw "La cotizacion debe estar 'SIN PEDIDO' para realizar la cancelacion"
            this.entity.status = 9;
            await this.entity.cancelar();
            this.entities = new Quotation(this.session);
            this.$toast.add(
              new Toast({
                summary: "Cotizacion",
                detail: "Cotizaion cancelada con exito"
              })
              );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
      async refresh() {
        this.customers = await new Customer(this.session).all();
        this.quotations = await new Quotation(this.session).all();
      },
      viewDocument(payload) {
        this.id_document = payload.id;
        this.documentDialog = true;
      }
    },
  };
  </script>
  
  <style scoped lang="scss">

  </style>
  